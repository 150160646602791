var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"de097be8e805e56267750f714ab2211518e3fa49"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { AndroidService } from '@/service/Android';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  ignoreErrors: ['Network request failed', 'Network Error'],
  release:
    process.env.VERCEL_GIT_COMMIT_SHA ||
    process.env.NEXT_PUBLLIC_VERCEL_GIT_COMMIT_SHA,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_LOCALHOST ? 0.0 : 0.4,
  environment:
    process.env.NEXT_PUBLIC_ENV !== 'development'
      ? 'production'
      : 'development',
  enabled: process.env.NEXT_PUBLIC_LOCALHOST ? false : true,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  integrations: [new Sentry.Replay()]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
Sentry.setTag('sdk-version', AndroidService.getSDKVersion() || 'unknown');
Sentry.setTag('sdk-number', AndroidService.getSDKNumber() || 'unknown');
